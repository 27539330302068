var CATEGORIES = (function () {
    var ALL_CATEGORIES = [
        {name:'staff',id:'0',type:'general',label:'PHYSICIANS'},
        {type: "general", id: "1", name: "departments", label: "DEPARTMENTS"},
        {type: "general", id: "2", name: "rooms", label: "ROOMS"},
        {type: "general", id: "52", name: "clinics", label: "CLINICS"},
        {type: "general", id: "78", name: "patient-care-units", label: "PATIENT_CARE_UNITS"},
        {type: "general", id: "5", name: "patient-services", label: "PATIENT_SERVICES"},
        {type: "general", id: "131", name: "patient-representative", label: "PATIENT_REPRESENTATIVES"},
        {type: "general", id: "238", name: "staff-services", label: "STAFF_SERVICES"},
        {type: "poi", id: "130", name: "artwork", label: "ARTWORK"},
        {type: "poi", id: "10", name: "banks-atm", label: "BANK_ATMS"},
        {type: "poi", id: "55", name: "bike-rack", label: "BIKE_RACK"},
        {type: "poi", id: "97", name: "charging-stations-electronics", label: "CHARGING_STATIONS"},
        {type: "poi", id: "96", name: "charging-stations-vehicles", label: "CHARGING_STATIONS_VEHICLES"},
        {type: "poi", id: "229", name: "conference-rooms", label: "CONFERENCE_ROOMS"},
        {type: "poi", id: "58", name: "courtyard", label: "COURTYARD"},
        {type: "poi", id: "16", name: "dining", label: "DINING"},
        {type: "poi", id: "15", name: "elevator", label: "ELEVATORS"},
        {type: "poi", id: "4", name: "entrance", label: "ENTRANCE_EXIT"},
        {type: "poi", id: "59", name: "hospitality", label: "HOSPITALITY"},
        {type: "poi", id: "81", name: "lobby", label: "LOBBY"},
        {type: "poi", id: "7", name: "parking", label: "PARKING"},
        {type: "poi", id: "80", name: "patient-travel", label: "PATIENT_TRAVEL"},
        {type: "poi", id: "12", name: "pharmacy", label: "PHARMACY"},
        {type: "poi", id: "94", name: "recreation-therapy", label: "RECREATION_THERAPY"},
        {type: "poi", id: "9", name: "restroom", label: "RESTROOMS"},
        {type: "poi", id: "11", name: "shopping", label: "SHOPPING"},
        {type: "poi", id: "22", name: "stairs", label: "STAIRS"},
        {type: "poi", id: "82", name: "transportation", label: "TRANSPORTATION"},
        {type: "poi", id: "83", name: "us-postal-box", label: "US_POSTAL_BOX"},
        {type: "poi", id: "21", name: "vending-machines", label: "VENDING_MACHINES"},
        {type: "poi", id: "90", name: "voucher-cashier", label: "VOUCHER_CASHIER"},
        {type: "poi", id: "176", name: "gingerbread-houses", label: "GINGERBREAD_HOUSES"},
        {type: "bethesda-points-of-interest", id: "75", name: "building", label: "BUILDING"},
        {type: "bethesda-points-of-interest", id: "16", name: "dining", label: "DINING"},
        {type: "bethesda-points-of-interest", id: "4", name: "entrance", label: "ENTRANCE_EXIT"},
        {type: "bethesda-points-of-interest", id: "132", name: "food-delivery", label: "FOOD_DELIVERY"},
        {type: "bethesda-points-of-interest", id: "91", name: "grocery", label: "GROCERY_STORES"},
        {type: "bethesda-points-of-interest", id: "24", name: "hotel", label: "HOTEL"},
        {type: "bethesda-points-of-interest", id: "7", name: "parking", label: "PARKING"},
        {type: "bethesda-points-of-interest", id: "92", name: "post-office", label: "POST_OFFICE"},
        {type: "bethesda-points-of-interest", id: "77", name: "security", label: "SECURITY"},
        {type: "bethesda-points-of-interest", id: "11", name: "shopping", label: "SHOPPING"},
        {type: "staff-services", id: "185", name: "career-services", label: "CAREER_SERVICES"},
        {type: "staff-services", id: "35", name: "community-services", label: "COMMUNITY_SERVICES"},
        {type: "staff-services", id: "110", name: "financial-services", label: "FINANCIAL_SERVICES"},
        {type: "staff-services", id: "186", name: "physical-services", label: "PHYSICAL_SERVICES"},
        {type: "staff-services", id: "187", name: "social-services", label: "SOCIAL_SERVICES"}
    ];

    var OUTDOOR_CATEGORIES = [
        { name: 'hotel', id: '37', type: 'outdoor', label: 'HOTEL', isPOI: true },
        { name: 'building', id: '75', type: 'outdoor', label: 'BUILDING', isPOI: true },
        { name: 'dining', id: '16', type: 'outdoor', label: 'DINING', isPOI: true },
        { name: 'shopping', id: '11', type: 'outdoor', label: 'SHOPPING', isPOI: true },
        { name: 'parking', id: '7', type: 'outdoor', label: 'PARKING', isPOI: true },
        { name: 'security', id: '77', type: 'outdoor', label: 'SECURITY', isPOI: true },
        { name: 'entrance', id: '4', type: 'outdoor', label: 'EXITS', isPOI: true },
        { name: 'grocery', id: '91', type: 'outdoor', label: 'GROCERY_STORES', isPOI: true },
        { name: 'post-office', id: '92', type: 'outdoor', label: 'POST_OFFICE', isPOI: true },
        { name: 'food-delivery', id: '132', type: 'outdoor', label: 'FOOD_DELIVERY', isPOI: true },
        { name: 'staff', id: '1000', type: 'outdoor', label: 'STAFF', isPOI: false }
    ];

    return {
        getAllCategoriesArray: function () {
            return ALL_CATEGORIES;
        },

        getOutdoorCategoriesArray: function () {
            return OUTDOOR_CATEGORIES;
        },

        getParentCategories: function() {
            var parentCategories = [];
            this.getAllCategoriesArray().forEach(function (item) {
                if (!parentCategories.includes(item.type) && item.type !== 'general') parentCategories.push(item.type);
            });
            return parentCategories;
        },

        getAllCategoriesClasses: function () {
            var categoriesNames = this.getAllCategoriesArray().map(function (category) {
                return category.name
            });
            return categoriesNames.concat(this.getParentCategories()).join(' ');
        }
    };
})();