var ShuttleController = function (parameters) {
	var _this = this;
	var ROUTES = CONFIG["SHUTTLE_ACTIVE_ROUTES"];//[12,1405];
	var BASE_URL = CONFIG["BASE_URL"];//'https://measecountryside-dev.connexient.com';
	var API_URL = CONFIG["API_URL"];//BASE_URL+'/api/';
	var GET_ROUTES_PATH = CONFIG["SYNC_GET_ROUTES_PATH"];//'get_rtpi_routes';
	var GET_STOPS_PATH = CONFIG["SYNC_GET_STOPS_PATH"];//'get_rtpi_route_stops';
    var GET_VEHICLES_PATH = CONFIG["SYNC_GET_VEHICLES_PATH"];//'get_rtpi_route_vehicles';
	var GET_STOP_ARRIVALS = CONFIG["SYNC_GET_STOP_ARRIVALS"];//'get_rtpi_route_arrivals_by_stop';


	this.getRoutes = function(){
		var deferred = jQuery.Deferred();
		var result = deferred.promise();
		var params = {};
		params.action = GET_ROUTES_PATH;
		params.formURL = API_URL;
		params.type = "GET";
		var $_ajax = submitForm(params);
		$_ajax.done(function(data){
			//console.log(data);
			deferred.resolve(data);
		});
		return result;
	}
	
	this.getStops = function(pRouteId){
		var deferred = jQuery.Deferred();
		var result = deferred.promise();
		var params = {};
		params.action = GET_STOPS_PATH;
		params.formURL = API_URL;
		params.type = "GET";
		params.pget ={'id':pRouteId};

		var $_ajax = submitForm(params);
		$_ajax.done(function(data){
			//console.log(data);
			deferred.resolve(data);
		});
		return result;
	}

    //added to get Vehicles
	this.getVehicles = function(pRouteId){
		var deferred = jQuery.Deferred();
		var result = deferred.promise();
		var params = {};
		params.action = GET_VEHICLES_PATH;
		params.formURL = API_URL;
		params.type = "GET";
		params.pget ={'id':pRouteId};

		var $_ajax = submitForm(params);
		$_ajax.done(function(data){
			//console.log(data);
			deferred.resolve(data);
		});
		return result;
	}

	function getStopInfo(pRoutes,pIndex,pDeferred,pStops,pStopsIds){
		var counter =pIndex;
		var stops = pStops;
		var stopsIds =pStopsIds;
		var route = pRoutes[counter];
		if(ROUTES.indexOf(route.id)!== -1){//TODO:: If all shuttle routes are implemented in My Maps we can delete this validation
			_this.getStops(route.id).done(function(stopsData){
				for(var j=0;j<stopsData.length;j++){
					var stop = stopsData[j];
					var stopId = stop.id;
					if(stopsIds.indexOf(stopId)===-1){
						stop['lng'] = stop.lon;
						stopsIds.push(stopId);
						stops.push(stop);
					}
				}

				if(counter === ROUTES.length-1){
					//console.log(stops);
					//console.log(stopsIds);
					pDeferred.resolve(stops);
				}else{
					counter++;
					getStopInfo(pRoutes,counter,pDeferred,stops,stopsIds);
				}
			});
		}
	}

	this.getAllStops = function(){
		var counter =0;
		var stops = [];
		var stopsIds =[];
		var deferred = jQuery.Deferred();
		var result = deferred.promise();
		var params = {};
		params.action = GET_ROUTES_PATH;
		params.formURL = API_URL;
		params.type = "GET";

		var $_ajax = submitForm(params);

		$_ajax.done(function(data){
			console.log(data);

			if(data.length>0){
				getStopInfo(data,counter,deferred,stops,stopsIds);
			}
		});
		return result;
	}

	//Gets all the arrivals for a stop
	this.getArrivalsByStop = function(pStopId){
		var deferred = jQuery.Deferred();
		var result = deferred.promise();
		var params = {};
		params.action = GET_STOP_ARRIVALS;
		params.formURL = API_URL;
		params.type = "GET";
		params.pget ={'id':pStopId};

		var $_ajax = submitForm(params);
		$_ajax.done(function(data){
			//console.log(data);
			deferred.resolve(data);
		});
		return result;
	}	

	//Filter the arrivals for stop. Only gets the next bus to arrive on each route 
	this.getNextRoutesArrivalsByStop = function(pStopId){
		var arrivals = [];
		var arrivalsInRoute = {};
		//var routeIds =[];
		var deferred = jQuery.Deferred();
		var result = deferred.promise();
		var params = {};
		params.action = GET_STOP_ARRIVALS;
		params.formURL = API_URL;
		params.type = "GET";
		params.pget ={'id':pStopId};

		var $_ajax = submitForm(params);
		$_ajax.done(function(data){
			var index = 0;
			//console.log(data);
			for(var i=0;i<data.length;i++){
				var arrival = data[i];
				//TODO:: If all shuttle routes are implemented in My Maps we can delete ROUTES.indexOf(arrival.route.id) validation
				if(/*routeIds.indexOf(arrival.route.id)=== -1 &&*/ arrival.secondsToArrival > 0 && ROUTES.indexOf(arrival.route.id)!== -1){
					//routeIds.push(arrival.route.id);
					if(typeof(arrivalsInRoute[arrival.route.id]) === "undefined"){
						arrivalsInRoute[arrival.route.id] = index;
						arrivals.push([]);
						index++;
					}
					arrivals[arrivalsInRoute[arrival.route.id]].push(arrival);
				}
				
			}
			//console.log(arrivals);
			deferred.resolve(arrivals);
		});
		return result;
	}

};

ShuttleController.prototype.kmlFilesMap =
{
    '12': '	RedLineShuttleRoute.kml',
    '1405': 'PurpleLineShuttleRoute.kml',
};
