function getPlacemarkByType(xml,type){
    var i,x;
    x = $(xml).find('Placemark');
    for (i = 0 ; i <x.length; i++) {
        var $placemark = $(x[i]); 
        var $type = $placemark.find('Data[name="Type"]');
        var typeValue = $type.first().text();
        typeValue = typeValue.trim();
        if(typeValue === type){
            return $placemark;
        }
    }
}

function getPlacemarkByName(xml,name){
    var i,x;
    x = $(xml).find('Placemark');
    for (i = 0 ; i <x.length; i++) {
        var $placemark = $(x[i]); 
        var $type = $placemark.find('name');
        var typeValue = $type.first().text();
        typeValue = typeValue.trim();
        if(typeValue === name){
            return $placemark;
        }
    }
}

function generatePlacemarkObject($place){
    var i;
    var $placemark = $place;
    var object={};
    if($placemark){
        object['name'] = $placemark.find('name').text().trim();
        object['description'] = $placemark.find('name').text().trim();
        var $extendedData = $placemark.find('Data');
        for (i = 0 ; i <$extendedData.length; i++) {
            var $d = $($extendedData[i]);
            var name = $d.attr('name');
            var value = $d.first().text().trim();
            object[name]=value;
        }
        if($placemark.find('LineString').length > 0){
            object['coordinates'] = $placemark.find('LineString coordinates').text().trim();
        }else if($placemark.find('Point').length > 0){
            object['coordinates'] = $placemark.find('Point coordinates').text().trim();
        }else if($placemark.find('Polygon').length > 0){
            object['coordinates'] = $placemark.find('Polygon coordinates').text().trim();
        }
    }
    return object;
}