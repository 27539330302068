var GoogleNavigationTranslator = function() {
};

GoogleNavigationTranslator.prototype.cManeuverIconTable =
[
	'transit_instruction_turn_sharp_left.png',
    'transit_instruction_uturn_right.png',
    'transit_instruction_turn_gentle_right.png',
    'transit_instruction_merge.png',
    'transit_instruction_roundabout_left.png',
    'transit_instruction_roundabout_right.png',
    'transit_instruction_uturn_left.png',
    'transit_instruction_turn_gentle_left.png',
    'transit_instruction_turn_left.png',
    'transit_instruction_ramp_right.png',
    'transit_instruction_turn_right.png',
    'transit_instruction_fork_right.png',
    'transit_instruction_straight.png',
    'transit_instruction_fork_left.png',
    'transit_instruction_ferry_train.png',
    'transit_instruction_turn_sharp_right.png',
    'transit_instruction_ramp_left.png',
    'transit_instruction_ferry.png',
    'transit_instruction_keep_left.png',
    'transit_instruction_keep_right.png'
];

GoogleNavigationTranslator.prototype.cManeuverType2Index =
{
    'turn-sharp-left': 0,
    'uturn-right': 1,
    'turn-slight-right': 2,
    'merge': 3,
    'roundabout-left': 4,
    'roundabout-right': 5,
    'uturn-left': 6,
    'turn-slight-left': 7,
    'turn-left': 8,
    'ramp-right': 9,
    'turn-right': 10,
    'fork-right': 11,
    'straight': 12,
    'fork-left': 13,
    'ferry-train': 14,
    'turn-sharp-right': 15,
    'ramp-left': 16,
    'ferry':17,
    'keep-left':18,
    'keep-right':19
};

GoogleNavigationTranslator.prototype.translateInstructions = function(pInstructions,pLegMetadata,pTranslator)
{
    var _this = this;

    for (var index = 0,l = pInstructions.length; index < l; index++)
    {
        _this._translateInstruction(pInstructions,index,pLegMetadata,pTranslator);
    }
}

GoogleNavigationTranslator.prototype._translateInstruction = function(pInstructions, pIndex,pLegMetadata,pTranslator)
{
    var lInstruction = pInstructions[pIndex];
    var lNextInstruction = pInstructions[pIndex+1];

    var routeDirection = pLegMetadata.direction;
    var modeTransportation = pLegMetadata.modeTransportation;
    var destination = pLegMetadata.legDestination;
    var origin = pLegMetadata.legOrigin;
    var hasShuttle = pLegMetadata.hasShuttle;

    var lInstructionManeuverIndex = this.cManeuverType2Index[lInstruction.maneuver];

    var lStringIcon = '';
    var lStringDetail = '';

    switch(lInstruction.maneuver)
    {
    case '':
        //TODO:: We need an icon for Go Ahead maneuver
    	lStringIcon = '';//this.cManeuverIconTable[12];
    	lStringDetail = this._replaceHTMLTags(lInstruction.instructions);
    	break;
 	case 'turn-sharp-left':
    case 'uturn-right':
    case 'turn-slight-right':
    case 'merge':
    case 'roundabout-left':
    case 'roundabout-right':
    case 'uturn-left':
    case 'turn-slight-left':
    case 'turn-left':
    case 'ramp-right':
    case 'turn-right':
    case 'fork-right':
    case 'straight':
    case 'fork-left':
    case 'ferry-train':
    case 'turn-sharp-right':
    case 'ramp-left':
    case 'ferry':
    case 'keep-left':
    case 'keep-right':
    	lStringIcon = this.cManeuverIconTable[lInstructionManeuverIndex];
    	lStringDetail = this._replaceHTMLTags(lInstruction.instructions);
    	break;
    default:
    	console.log('Can\'t find translation for maneuverType:'+lInstruction.maneuverType);
        break;
    } // switch statement

    //Adding Extra data to instruction if it's necessary
    if(pIndex === 0){//First
        lStringDetail = this._getFirstInstructionPreAction(lStringDetail,origin,routeDirection,modeTransportation,pTranslator,hasShuttle);
    }else if(!lNextInstruction){//Last
        lStringDetail = this._getLastInstructionString(lStringDetail,destination,routeDirection,modeTransportation,pTranslator);
    }

    lInstruction.detail = lStringDetail;
    lInstruction.icon = lStringIcon;
    lInstruction.gg_inst_index = pIndex;

} // end NavigationSolver.prototype.translateInstruction

GoogleNavigationTranslator.prototype._replaceHTMLTags = function(pStringWithTags)
{
	pStringWithTags = pStringWithTags.replace(/<div[^>]*>/g,'. ');
    pStringWithTags = jQuery("<div/>").html(pStringWithTags).text();
    return pStringWithTags;
}

GoogleNavigationTranslator.prototype._getLastInstructionString = function(plStringDetail,pDestination,pRouteDirection,pModeTrans,pTranslator){
    if(pRouteDirection === 'outdoor-indoor'){
        if(pModeTrans === 'driving'){
            var text = plStringDetail;
            text += (pDestination.category!== 'entrance')?'. '+pTranslator.getLangLabel('NAV_YOU_ARE_ARRIVING')+pTranslator.getLangLabel('AT')+pDestination.name:'. '+pTranslator.getLangLabel('NAV_YOU_ARE_ARRIVING')+pTranslator.getLangLabel('AT')+pTranslator.getLangLabel('BUILDING').toLowerCase();
            return text;
        }else{

            return plStringDetail +'. '+pTranslator.getLangLabel('NAV_YOU_ARE_ARRIVING')+pTranslator.getLangLabel('AT')+pDestination.name+'. ';
        }
    }else{
        return plStringDetail;
    }
}

GoogleNavigationTranslator.prototype._getFirstInstructionPreAction = function(plStringDetail,pOrigin,pRouteDirection,pModeTrans,pTranslator,pHasShuttle){
    if(pRouteDirection === 'indoor-outdoor'){
        if(pModeTrans === 'driving'){
            var text = (pOrigin.category!== 'entrance')?pTranslator.getLangLabel('NAV_EXIT')+' '+pOrigin.name+'. ':pTranslator.getLangLabel('NAV_EXIT_BUILDING')+'. ';
            return text + plStringDetail;
        }else if(pModeTrans === 'transit' && pHasShuttle){
            return pTranslator.getLangLabel('NAV_YOU_ARE_ARRIVING')+pTranslator.getLangLabel('AT')+pOrigin.name+'. ' + plStringDetail;
        }else{
            return plStringDetail;
        }
    }else{
        return plStringDetail;
    }
}
