/**
 * @public
 * @name MyNavigation
 * @class Navigation Example class.
 * It allows the rendering of navigation instructions if available from computeRoute().
 * Creates a navigation object to simplify the display of instructions.
 * It uses for the media directory the value of vg.imagePath (by default "../media"), which contains:
 * <ul>
 * <li>images for transit instructions: transit_*.png
 * </ul>
 * @see vg.mapviewer.kiosk.Mapviewer#computeRoute
 * @see vg.mapviewer.web.Mapviewer#computeRoute
 * @param {vg.mapviewer.Mapviewer} pMapViewer
 * @param {object} pNavigationData, result of vg.mapviewer.Mapviewer.computeRoute()
 * @param {object} vg_ids, place id name correspondance, using the same file format as ids.json: {"targets":["default"],"labels":{"UL0-ID0003":["UL0-ID0003","Zara"],...} }
 *
 * @example
 <code>
 This class asummes that the following elements exist on your .html file

&lt;div id="instructions" class="instructions"&gt;
	&lt;div id="instructions_prev_button" class="instructions"&gt;&lt;img src="media/leftArrow.png"/&gt;&lt;/div&gt;
	&lt;div id="instructions_count" class="instructions"&gt;&lt;/div&gt;
	&lt;div id="instructions_brief" class="instructions"&gt;&lt;/div&gt;
	&lt;div id="instructions_detail" class="instructions"&gt;&lt;/div&gt;
	&lt;img id="instructions_icon" class="instructions"&gt;&lt;/img&gt;
	&lt;div id="instructions_time" class="instructions"&gt;&lt;/div&gt;
	&lt;div id="instructions_next_button" class="instructions"&gt;&lt;img src="media/rightArrow.png"/&gt;&lt;/div&gt;
&lt;/div&gt;
</code>
 *
 * @example
<code>
pNavigationData will have the form

{ "navigation": {
 "instructions" : [{
	"icon": "transit_instruction_turn_left.png",
	"dataset": "0",
	"modality": "pedestrian",
	"time": "0.000000",
	"totalTime": "45.953415","position" : { "lat" : "48.782332", "lon" : "2.221195" },
	"detail": "Go straight for a few seconds then turn left",
	"brief": "Go straight",
	"duration": " for a few seconds"
  }
  ,...
  ]
}
</code>
* @since 1.7.18 added ID of waypoints and destination
*/
var MyNavigation = function(pMapViewer,pComplexRoute,vg_ids,floorTags,pGController,pTranslator,pMultiBuildingView,pOptions)
{
	// For Debugging.
	//window.navigationData = pNavigationData;

	// false is the default for 1.7.16 and below, true for 1.7.17 and above.
	// on 1.7.17 navigation data also has parameters which where use to calculate navigation.
	//var lNavigationParameters = (pNavigationData && pNavigationData.navigation && pNavigationData.navigation.parameters) ? pNavigationData.navigation.parameters : {};
	//var mergeFloorChangeInstructions = (lNavigationParameters && typeof(lNavigationParameters.mergeFloorChangeInstructions) !== 'undefined') ? lNavigationParameters.mergeFloorChangeInstructions : false;
	var mergeFloorChangeInstructions = pComplexRoute.getMergeFloorChangeInstructions();

	var mulBuildingView = pMultiBuildingView;
	var gController = pGController;
	var complexRoute = pComplexRoute;
	var imagePath = vg.imagePath || '/web/media';
	var vgcanvas = '#'+CONFIG["VG_CONTAINER_ID"]+' canvas';//'#vg_map_container canvas';
	var ggcanvas = '#'+CONFIG["GG_CONTAINER_ID"];//#gg_map_container';
	var translator = pTranslator;

	var instructions;
	var stages;
	var mapviewer = pMapViewer;
	var floors = floorTags;

	// start by saying no instruction has been set
	var stepBystepMode = false;
	var currentInstructionIndex = 0;
	var numberOfInstructions;
	var totalStages =0;
	var currentStageIndex=0;


	var instructionOverlays = [];
	var stagesOverlays =[];

	var instructionInfoList = [];
	var stagesInfoList = [];

	// If you want to highlight the curent instruction for debugging.
	var showCurrentInstructionSegment = false;

	// show all instruction segments at the beginning.
	var debugInstructionSegments = false;

	var _this = this;

	var deferredImages;
	var currentImageIndex=0;
	var routeImages=[];

	/**
	* @public
	* @name isValid
	* @function
	* @memberOf MyNavigation#
	* @description
	* returns false if there was no navigation data (for example missing "computeNavigation: true" in the routing request)
	* @return {boolean} false if there was no navigation data
	*/
	/*this.isValid = function()
	{
		return mValid;
	}*/

	this.getCurrentInstruction = function(){
		return instructions[currentInstructionIndex];
	}

	this.getCurrentStage =function(){
		return stages[currentStageIndex];
	}

	this.getRouteStages=function(){
		return stages;
	}

	//Set true if step-by-step
	this.setNavigationMode = function(mode){
		stepBystepMode = (mode == 'preview')?false:true;
	}
	//Returns true if step-by-step
	this.getNavigationMode = function(){
		return stepBystepMode;
	}

	this.getFloorByVgId = function(index){
		if(index === 'outside'){
			return {name:'Outside'};
		}else{
			var arr = jQuery.grep(floors, function( f ) {
			  return f.vg_floor_id === index;
			});
			return arr[0];
		}
	}


	/**
	 * @public
	 * @name navigationInstructionRadius
	 * @type number
	 * @field
	 * @memberOf MyNavigation
	 * @description radius in meters to use when moving the camera to the beginning of an instruction.
	 */
	//this.navigationInstructionRadius = 50;
	this.navigationInstructionMinRadius = CONFIG["VG_NAV_INST_MIN_RADIUS"];//200;
	this.navigationInstructionMinRadiusSBS = CONFIG["VG_NAV_INST_MIN_RADIUS_SBS"];//100;
	this.navigationInstructionsPitch = CONFIG["VG_NAV_INST_PITCH"];//-60;
	this.navigationImagePitch = CONFIG["VG_NAV_IMG_PITCH"];//-80;
	this.navigationInstructionsAnimation = CONFIG["VG_NAV_INST_ANIMATION"];//2;

	/*if (pMapViewer.sdkType === "web2d")
	{
		this.navigationInstructionRadius = 25;
	}*/

	/**
	* @public
	* @name displayNextInstruction
	* @function
	* @memberOf MyNavigation#
	* @description
	* displays the previous instruction if possible and move the camera to the start of the instruction
	*/
	this.displayNextInstruction = function()
	{
		if (currentInstructionIndex < (numberOfInstructions - 1))
		{
			currentInstructionIndex++;
			var inst = instructions[currentInstructionIndex];
			if(inst.type==='vg'){
				displayInstruction(currentInstructionIndex);
				goToCurrentInstruction();

				displayLibInfo();
			}else{
				displayOutdoorInstruction();
				displayOutdoorLibInfo();
				mapviewer.trigger('MyNavigation.instructionComplete');
			}

			updateInstructionsPanel();
			displayInstructionControls(currentInstructionIndex);
		}
	}

	/**
	* @public
	* @name displayPrevInstruction
	* @function
	* @memberOf MyNavigation#
	* @description
	* displays the previous instruction if possible and move the camera to the start of the instruction
	*/
	this.displayPrevInstruction = function()
	{
		if (currentInstructionIndex > 0)
		{
			currentInstructionIndex--;
			var inst = instructions[currentInstructionIndex];
			if(inst.type==='vg'){
				displayInstruction(currentInstructionIndex);
				goToCurrentInstruction();

				displayLibInfo();
			}else{
				displayOutdoorInstruction();
				displayOutdoorLibInfo();
				mapviewer.trigger('MyNavigation.instructionComplete');
			}
			updateInstructionsPanel();
			displayInstructionControls(currentInstructionIndex);
		}
	}

	this.gotoInstruction = function(index){
		if(index >= 0 && index < numberOfInstructions){
			currentInstructionIndex = index;
			var inst = instructions[currentInstructionIndex];
			if(inst.type==='vg'){
				displayInstruction(currentInstructionIndex);
				goToCurrentInstruction();
				displayLibInfo();
			}else{
				displayOutdoorInstruction();
				displayOutdoorLibInfo();
				mapviewer.trigger('MyNavigation.instructionComplete');
			}
			updateInstructionsPanel();
			displayInstructionControls(currentInstructionIndex);
		}
	}

	/**
	* @public
	* @name displayNextStage
	* @function
	* @memberOf MyNavigation#
	* @description
	* displays the previous stage if possible and move the camera to the start of the stage
	*/
	this.displayNextStage = function()
	{
		if (currentStageIndex < (totalStages - 1))
		{
			currentStageIndex++;
			var stage = stages[currentStageIndex];
			if(stage.map_type==='indoor'){
				displayStage(currentStageIndex);
				goToCurrentStage();

				displayStageLibInfo();
			}else{
				goToOutdoorStage();
				displayOutdoorStageLibInfo();
				mapviewer.trigger('MyNavigation.stageComplete');
			}
			updateInstructionsPanel();
			displayStageControls(currentStageIndex);
		}
	}

	/**
	* @public
	* @name displayPrevStage
	* @function
	* @memberOf MyNavigation#
	* @description
	* displays the previous stage if possible and move the camera to the start of the stage
	*/
	this.displayPrevStage = function()
	{
		if (currentStageIndex > 0)
		{
			currentStageIndex--;
			var stage = stages[currentStageIndex];
			if(stage.map_type==='indoor'){
				displayStage(currentStageIndex);
				goToCurrentStage();

				displayStageLibInfo();
			}else{
				goToOutdoorStage();
				displayOutdoorStageLibInfo();
				mapviewer.trigger('MyNavigation.stageComplete');
			}
			updateInstructionsPanel();
			displayStageControls(currentStageIndex);
		}
	}

	this.gotoStage = function(index){
		if(index >= 0 && index < totalStages){
			currentStageIndex = index;
			var stage = stages[currentStageIndex];
			if(stage.map_type==='indoor'){
				displayStage(currentStageIndex);
				goToCurrentStage();
				displayStageLibInfo();
			}else{
				goToOutdoorStage();
				displayOutdoorStageLibInfo();
				mapviewer.trigger('MyNavigation.stageComplete');
			}
			updateInstructionsPanel();
			displayStageControls(currentStageIndex);
		}
	}

	/*Get a Shuttle POI key and execute gotoStage*/
	/*specialCase is true when direction is from indoor to outdoor because the poi for metro station is the beginnis of the ourdoor route
	created by google maps*/
	this.goToShuttleStageFromPOI = function(pKey){
		var specialCase = false;
		var index = -1;
		var key = pKey;
		var pointKey = 'start';
		//var routeDirection = complexRoute.getRouteDirection();
		var routeShuttleStage = complexRoute.getShuttleStage();
		var routeDirection = routeShuttleStage.direction;
		var shuttleRoute = gController.getShuttleMetroRoute();
		var shuttleStage = shuttleRoute.stages[routeDirection];
		var shuttleStageIndex = -1;

		if(routeDirection==='indoor-outdoor' && key === 'start'){
			specialCase = true;
			pointKey = 'end';
		}
		for(var i=0; i < shuttleStage.length; i++){
			if(shuttleStage[i][pointKey] === key){
				shuttleStageIndex = i;
				break;
			}
		}
		if(shuttleStageIndex !== -1){
			for(var i=0; i < stages.length; i++){
				if(stages[i]['isShuttle']){
					if(specialCase){
						index = stages[i]['stage_index'] +1;
						break;
					}else if(stages[i]['si_stage_index'] === shuttleStageIndex){
						index = stages[i]['stage_index'];
						break;
					}
				}
			}
			if(index !== -1){
				_this.setNavigationMode('preview');
				_this.gotoStage(index);
			}
		}
		
		return index;
	}


	/**
	* @public
	* @name remove
	* @function
	* @memberOf MyNavigation#
	* @description
	* clear all information associated with the navigation.
	*/
	this.remove = function()
	{
		currentInstructionIndex = 0;
		numberOfInstructions = 0;
		numberOfIndoorInstructions=0;
		stepBystepMode = false;
		totalStages =0;


		jQuery('#instructions_detail').html('');
		jQuery('#instructions_brief').html('');
		//jQuery('#instructions_count').html('0/0');
		jQuery('#instructions_count').html('');
		jQuery('#instructions_time').html('');
		jQuery('#instructions_icon').attr("src",'');

		this.removeInstructionOverlays();
		this.removeStageOverlays();

		this.removeInstructionInfo();
		this.removeStageInfo();
	}

	/**
	* @public
	* @name removeInstructionOverlays
	* @function
	* @memberOf MyNavigation#
	* @description
	* clear any instruction extra overlays.
	*/
	this.removeInstructionOverlays = function()
	{
		for (i in instructionOverlays)
		{
			instructionOverlays[i].remove();
		}
		instructionOverlays = [];

	}

	this.removeStageOverlays = function(){
		for (i in stagesOverlays)
		{
			stagesOverlays[i].remove();
		}
		stagesOverlays = [];
	}

	/**
	* @public
	* @name loadPreviewInstructions
	* @function
	* @memberOf MyNavigation#
	* @description
	* create a list of the preview instructions.
	* This method assumes exist a container with id #map-instructions
	*/
	this.loadPreviewInstructions =function(){
		$('#map-instructions').html('');
		for(var i=0; i<stages.length;i++){
			var baseUrl = CONFIG["BASE_URL"];
			var stage = stages[i];
			var stageInstructions = stage.instructions;
			var floor = stage['floor'];
			var floorData = _this.getFloorByVgId(floor);
			floorData = (floorData!==null && typeof(floorData)!=='undefined')?floorData:{name:'Outside'}; //From Vg instructions I'm getting floor outside
			var initialInst = stageInstructions[0];
			var lastInst = stageInstructions[stageInstructions.length -1];
			var initialStep = initialInst.global_inst_index +1;
			var endStep = lastInst.global_inst_index +1;
			var temp = '<ul class="level-panel">';
			temp+='<li class="level-head" data-index="'+stage.stage_index+'">';
			temp+= (stage.map_type==='outdoor')?'<span class="title">'+stage.name+'</span><br><span class="step-item">'+translator.getLangLabel('STAGE')+' '+(stage.stage_index+1)+': '+translator.getLangLabel('STEPS')+' '+initialStep+' - '+endStep+' '+translator.getLangLabel('GOOGLE_MAPS')+'</span>':'<span class="title">'+stage.name+': '+floorData.name+'</span><br><span class="step-item">'+translator.getLangLabel('STAGE')+' '+(stage.stage_index+1)+': '+translator.getLangLabel('STEPS')+' '+initialStep+' - '+endStep+' '+translator.getLangLabel('NIH_MAPS')+'</span>';
			temp+= '<span class="icon triangle-bottom-red"></span><ul class="inner-panel">';
			
			for(var j=0; j<stageInstructions.length;j++){
				var inst = stageInstructions[j];
				temp+='<li class="list-item instruction" data-type="'+inst.type+'" data-index='+inst.global_inst_index+'>'+(inst.global_inst_index+1)+'. '+inst.detail+'<span class="image">';
				if(inst.icon!==''){
					temp+= '<img src="'+baseUrl+'/media/'+inst.icon+'">' //27 Sep 2021 Fixed url for prod
				}
				temp+= '</span></li>';
			}
			temp+= '</ul></li></ul>';
			$('#map-instructions').append(temp);
		}
	}

	this.getRouteImages=function(){
		deferredImages = jQuery.Deferred();
		var result = deferredImages.promise();
		currentImageIndex =0;
		routeImages=[];
		getStageImage();
		return result;
	}

	function resetToOldState(){
		if(stepBystepMode){
			_this.gotoInstruction(currentInstructionIndex);
		}else{
			_this.gotoStage(currentStageIndex);
		}
	}

	function getStageImage(){
		if(currentImageIndex == totalStages){
			currentImageIndex =0;
			resetToOldState();
			deferredImages.resolve(routeImages);
			return;
		}
		//var currentStage = floorInstructions[currentImageIndex];
		var currentStage = stages[currentImageIndex];
		if(currentStage.map_type==='outdoor'){
			getOutdoorImageProcess();
		}else{
			getIndoorImageProcess();
		}
		
	}

	function getOutdoorImageProcess(){
		/*In Chrome html2canvas not being able to render css transforms.
		 So what we could do is to grab the values that the container is shifted, remove the transform, 
		 assign left and top from the values we got off transform then use html2canvas.
		  Then so the map doesn't break, we reset the map's css values when html2canvas is done.*/
		var tempMap = gController.getMap();
		var stage = stages[currentImageIndex];
		var instructions = stage.instructions;
		var originStage = stage.stage_start_point;
		var destStage = stage.stage_end_point;

		google.maps.event.addListenerOnce(tempMap, 'idle', function() {
			var isHidden = false;  
			if($(ggcanvas).css('display')==='none'){
				isHidden=true;
				$(ggcanvas).css('display','block');
			}
			var transform=$(".gm-style>div:first>div").css("transform")
			var comp=transform.split(",") //split up the transform matrix
			var mapleft=parseFloat(comp[4]) //get left value
			var maptop=parseFloat(comp[5])  //get top value
			$(".gm-style>div:first>div").css({ //get the map container. not sure if stable
			  "transform":"none",
			  "left":mapleft,
			  "top":maptop,
			});
			html2canvas($(ggcanvas), {
				useCORS: true,
				onrendered: function(canvas) {
				  	var dataUrl= canvas.toDataURL('image/jpeg');
				    $(".gm-style>div:first>div").css({
				      left:0,
				      top:0,
				      "transform":transform
				    });
				  	routeImages.push(dataUrl);
				  	if(isHidden){
						$(ggcanvas).css('display','');
					}
					currentImageIndex++;
					getStageImage();
				}
			});	
		});  
		setGoogleViewport(originStage,destStage);
	}

	function getIndoorImageProcess(){
		var currentStage = stages[currentImageIndex];
		var stageInstructions = currentStage.instructions;
		var currentFloor = mapviewer.getCurrentFloor();
		var currentMapStage = mulBuildingView.getCurrentExploreState();
		var firstInstruction = stageInstructions[0];
		var lastInstruction = stageInstructions[stageInstructions.length-1];
		var instructionFloor = currentStage['floor'];

		// available on SDKs and datasets with offline routing
		var position;
		var converted_points = [];

		converted_points = [];
		var rotation = mapviewer.camera.rotation;
		if(lastInstruction){
			converted_points.push(mapviewer.convertLatLonToPoint(firstInstruction['position']));
			converted_points.push(mapviewer.convertLatLonToPoint(lastInstruction['position']));
			rotation = angleFromCoordinate((firstInstruction['position']).lat,(firstInstruction['position']).lon,(lastInstruction['position']).lat,(lastInstruction['position']).lon);
		}else{
			converted_points.push(mapviewer.convertLatLonToPoint(firstInstruction['position']));
			rotation = angleFromCoordinate((firstInstruction['position']).lat,(firstInstruction['position']).lon,(firstInstruction['position']).lat,(firstInstruction['position']).lon);
		}

		if (currentFloor != instructionFloor)
		{
			position = calculateViewpoints(converted_points,true);
			multiBuildingView.goTo({
				mode: 'floor',
				viewpoint: {
					position: position,
					pitch: _this.navigationImagePitch,
					heading: 0
				},
				floorID: instructionFloor
			}).done(function(){
				mapviewer.addPostRenderListener(_this.postRenderListener);
			});
		}
		else
		{
			mapviewer.camera.pitch = _this.navigationImagePitch;
			mapviewer.camera.rotation = 0;//Math.round(rotation);
			position = calculateViewpoints(converted_points,true);
			mapviewer.camera.position=position;
			mapviewer.addPostRenderListener(_this.postRenderListener);
		}
	}

	this.postRenderListener = function(){
		var deferred = jQuery.Deferred();
		var result = deferred.promise();
		var image = captureImage();
		routeImages.push(image);
		currentImageIndex++;
		getStageImage();
	}

	function captureImage(){
		var canvas = document.querySelector(vgcanvas);
		var pdfImageData = canvas.toDataURL('image/jpeg');
		mapviewer.removePostRenderListener(_this.postRenderListener);
		return pdfImageData;
	}


	function calculateViewpoints(points,stepbystep){
		var position;
		var minRadius =(stepbystep)?_this.navigationInstructionMinRadiusSBS:_this.navigationInstructionMinRadius;
		position = mapviewer.getViewpointFromPositions({
			points: points,
			top: 110,
			bottom: 120,
			left:500
		});
		if(position.radius < minRadius){
			position.radius = minRadius;
		}

		return position;
	}
	/**
	* @private
	* calls mapviewer.camera.goTo(), if necessary it calls mapviewer.changeFloor()
	*/
	function goToCurrentInstruction()
	{
		if (currentInstructionIndex == -1)
		{
			currentInstructionIndex = 0;
		}

		var nextInstruction = null;
		var instruction = instructions[currentInstructionIndex];
		var instStage = stages[instruction.stage_index];
		var floorInst = instStage.instructions;
		var currentFloor = mapviewer.getCurrentFloor();
		var instructionFloor = instruction['dataset'];
		var firstFloorInstruction = floorInst[0];
		var lastFloorInstruction = floorInst[floorInst.length - 1];


		if(instruction.stage_inst_index < floorInst.length -1){
			nextInstruction = instructions[currentInstructionIndex + 1];
		}

		// available on SDKs and datasets with offline routing
		var position;
		var seeWholeInstruction = false;
		var converted_points = [];

		if (seeWholeInstruction
			&& instruction.positions
			&& instruction.positions.length > 0)
		{
			var points = instruction.positions;
			converted_points = [];
			for(var j = 0, jl = points.length ; j < jl; j++)
			{
				var point = points[j];
				// transfor to new coordinates
				point = mapviewer.convertLatLonToPoint(point);

				converted_points.push(point);
			}
			position = calculateViewpoints(converted_points,true);

		}
		else
		{
			// If you want to keep same height as currently for instructions.
			//_this.navigationInstructionRadius = mapviewer.camera.position.radius;
			converted_points = [];
			var rotation = mapviewer.camera.rotation;
			//rotation = angleFromCoordinate((lastFloorInstruction['position']).lat,(lastFloorInstruction['position']).lon,(firstFloorInstruction['position']).lat,(firstFloorInstruction['position']).lon);
			rotation = angleFromCoordinate((firstFloorInstruction['position']).lat,(firstFloorInstruction['position']).lon,(lastFloorInstruction['position']).lat,(lastFloorInstruction['position']).lon); //CORE-693
			if(nextInstruction){
				converted_points.push(mapviewer.convertLatLonToPoint(instruction['position']));
				converted_points.push(mapviewer.convertLatLonToPoint(nextInstruction['position']));
			}else{
				converted_points.push(mapviewer.convertLatLonToPoint(instruction['position']));
			}
		}

		

		if (currentFloor != instructionFloor)
		{
			position = calculateViewpoints(converted_points,true);
			mapviewer.camera.rotation = Math.round(rotation);
			multiBuildingView.goTo({
				mode: 'floor',
				viewpoint: {
					position: position,
					pitch: _this.navigationInstructionsPitch,
					/*heading: Math.round(rotation)*/
				},
				floorID: instructionFloor
			}).done(function(){
				mapviewer.trigger('MyNavigation.instructionComplete');
			});
		}
		else
		{
			mapviewer.camera.pitch = _this.navigationInstructionsPitch;
			mapviewer.camera.rotation = Math.round(rotation);
			position = calculateViewpoints(converted_points,true);
			mapviewer.camera.goTo(position).done(function(){
				mapviewer.trigger('MyNavigation.instructionComplete');
			});
		}
	}

	function goToCurrentStage(){
		if (currentStageIndex == -1)
		{
			currentStageIndex = 0;
		}

		var currentStage = stages[currentStageIndex];
		var stageInstructions = currentStage.instructions;
		var currentFloor = mapviewer.getCurrentFloor();
		var firstInstruction = stageInstructions[0];
		var lastInstruction = stageInstructions[stageInstructions.length-1];
		var instructionFloor = currentStage['floor'];

		// available on SDKs and datasets with offline routing
		var position;
		var converted_points = [];

		converted_points = [];
		var rotation = mapviewer.camera.rotation;
		if(lastInstruction){
			converted_points.push(mapviewer.convertLatLonToPoint(firstInstruction['position']));
			converted_points.push(mapviewer.convertLatLonToPoint(lastInstruction['position']));
			//rotation = angleFromCoordinate((lastInstruction['position']).lat,(lastInstruction['position']).lon,(firstInstruction['position']).lat,(firstInstruction['position']).lon);
			rotation = angleFromCoordinate((firstInstruction['position']).lat,(firstInstruction['position']).lon,(lastInstruction['position']).lat,(lastInstruction['position']).lon); //CORE-693
		}else{
			converted_points.push(mapviewer.convertLatLonToPoint(firstInstruction['position']));
			rotation = angleFromCoordinate((firstInstruction['position']).lat,(firstInstruction['position']).lon,(firstInstruction['position']).lat,(firstInstruction['position']).lon);
		}

		if (currentFloor != instructionFloor)
		{
			position = calculateViewpoints(converted_points,false);
			mapviewer.camera.rotation = Math.round(rotation);
			multiBuildingView.goTo({
				mode: 'floor',
				viewpoint: {
					position: position,
					pitch: _this.navigationInstructionsPitch,
					//heading: Math.round(rotation)
				},
				floorID: instructionFloor
			}).done(function(){
				mapviewer.trigger('MyNavigation.stageComplete');
			});
		}
		else
		{
			mapviewer.camera.pitch = _this.navigationInstructionsPitch;
			mapviewer.camera.rotation = Math.round(rotation);
			position = calculateViewpoints(converted_points,false);
			mapviewer.camera.goTo(position).done(function(){
				mapviewer.trigger('MyNavigation.stageComplete');
			});
		}
	}

	function getFloorInstructions(floor){
		for(var i=0;i<floorInstructions.length;i++){
			if(floorInstructions[i]['floor'] === floor){
				return floorInstructions[i]['instructions'];
			}
		}
	}

	function displayLibInfo(){
		_this.removeInstructionInfo();
		_this.removeStageInfo();
		if (currentInstructionIndex == -1)
		{
			currentInstructionIndex = 0;
		}
		var isNew = true;
		var instruction = instructions[currentInstructionIndex];
		var instStage = stages[instruction.stage_index];
		if((instStage.stage_index == totalStages - 1 || stages[instStage.stage_index + 1].map_type!== 'indoor') && instruction.stage_inst_index === instStage.instructions.length - 1){
			return;
		}

		var nextInstruction = instructions[currentInstructionIndex + 1];
		var prevInstruction = null;
		var position = (instruction.maneuverType === 'eVgManeuverTypeGoDown' || instruction.maneuverType === 'eVgManeuverTypeGoUp')?mapviewer.convertLatLonToPoint( instruction['position'] ):mapviewer.convertLatLonToPoint( nextInstruction['position'] );
		var selector = instruction.icon.replace(".png","");
		var currentFloor = instruction['dataset'];
		var nextInstructionFloor = nextInstruction['dataset'];

		if (currentFloor != nextInstructionFloor){
			nextInstructionFloor = currentFloor;
		}

		if(currentInstructionIndex !== 0){
			prevInstruction = instructions[currentInstructionIndex - 1];
		}

		
		var poictn = $('<div>').html($('#map-lib-template').html());
		var lib = null;
		if($('#'+selector).length > 0){
			isNew = false;
			lib = $('#'+selector);
		}else{
			lib = poictn.find('.lib');
			lib.attr('id',selector).addClass('transit-instruction').addClass(selector);
			lib.find('.small-ctn .icon').addClass(selector);
		}
		if(Boolean(isNew)){
			$('#hidden-div').append(poictn.html());
			hookLibEvent(selector);
		}

		var pos = {x: position.x, y: position.y, radius: mapviewer.camera.position.radius};
		
		var poi = mapviewer.addPOI({selector: '#'+selector, floor: nextInstructionFloor, position: pos,alignment: {x: 0, y: 1.0}});
		instructionInfoList.push({vg:poi,selector:'#'+selector});
	}

	function hookLibEvent(selector){
		$('#'+selector).bind(clickEvent,function(e){
			if(e.preventDefault){e.preventDefault();}
			$('#instructions_next_button').click();
		});
	}

	function displayStageLibInfo(){
		//var yha = false;
		_this.removeStageInfo();
		_this.removeInstructionInfo();
		if (currentStageIndex == -1)
		{
			currentStageIndex = 0;
		}
		if(currentInstructionIndex == numberOfInstructions -1){
			return;
		}
		var stage = stages[currentStageIndex];
		var currentFloor = Number(stage.floor);
		var stageInstructions = stage['instructions'];
		var firstInstruction = stageInstructions[0];
		var lastInstruction = stageInstructions[stageInstructions.length-1];
		var libInstructions = [];

		if(firstInstruction.maneuverType === 'eVgManeuverTypeGoDown' || firstInstruction.maneuverType === 'eVgManeuverTypeGoUp'){
			libInstructions.push({
				position:firstInstruction.position,
				icon:firstInstruction.icon,
				dataset:firstInstruction.dataset,
				vg_inst_index:firstInstruction.vg_inst_index,
				goToNext:true
			});
		}
		if(lastInstruction.maneuverType === 'eVgManeuverTypeGoDown' || lastInstruction.maneuverType === 'eVgManeuverTypeGoUp'){
			libInstructions.push({
				position:lastInstruction.position,
				icon:lastInstruction.icon,
				dataset:lastInstruction.dataset,
				vg_inst_index:lastInstruction.vg_inst_index,
				goToNext:true
			});
		}

		$.each(libInstructions,function(i,inst){
			var goToNext = (inst.goToNext)?inst.goToNext:false;
			var isNew = true;
			var position = mapviewer.convertLatLonToPoint( inst['position'] ); 
			var iconSelector = inst.icon.replace(".png","");
			var selector = iconSelector+'_'+inst.vg_inst_index;

			var poictn = $('<div>').html($('#map-lib-template').html());
			var lib = null;
			if($('#'+selector).length > 0){
				isNew = false;
				lib = $('#'+selector);
			}else{
				lib = poictn.find('.lib');
				lib.attr('id',selector).addClass('transit-instruction').addClass(selector);
				lib.find('.small-ctn .icon').addClass(iconSelector);
			}
			if(Boolean(isNew)){
				$('#hidden-div').append(poictn.html());
				hookStageLibEvent(selector,goToNext);
			}

			var pos = {x: position.x, y: position.y, radius: mapviewer.camera.position.radius};
			
			var poi = mapviewer.addPOI({selector: '#'+selector, floor: inst['dataset'], position: pos,alignment: {x: 0, y: 1.0}});
			stagesInfoList.push({vg:poi,selector:'#'+selector});
		});
	}

	function hookStageLibEvent(selector,goToNext){
		var btn = (goToNext)?'stage_next_button':'stage_prev_button';
		$('#'+selector).bind(clickEvent,function(e){
			if(e.preventDefault){e.preventDefault();}
			$('#'+btn).click();
		});
	}

	function displayOutdoorLibInfo(){
		var instruction = instructions[currentInstructionIndex];
		if(instruction.type === 'si'){
			gController.displayShuttleStepMarker(instruction.inst_start_point,instruction.inst_end_point);
		}else{
			gController.displayStepMarker(instruction.gg_route_index,instruction.gg_inst_index);
		}
	}

	function displayOutdoorInstruction(){
		var instruction = instructions[currentInstructionIndex];
		setGoogleViewport(instruction.inst_start_point,instruction.inst_end_point);
	}

	function displayOutdoorStageLibInfo(){
		var startPoint = {lat:0,lng:0};
		var endPoint = {lat:0,lng:0};
		var offset = new google.maps.Size(0,-55);
		var stage = stages[currentStageIndex];
		var stageTitle = (stage.isShuttle)?stage.name:(stage.stage_mode==='transit')?translator.getLangLabel('TRANSIT_ROUTE'):(stage.stage_mode==='walking')?translator.getLangLabel('WALKING_ROUTE'):translator.getLangLabel('STREET_ROUTE');
		startPoint = stage.stage_start_point;
		endPoint = stage.stage_end_point;

		if(currentStageIndex !== 0 && currentStageIndex!== totalStages-1){
			gController.displayStageMarker(startPoint,endPoint);
		}
		gController.displayInfoWindow(startPoint,{name:translator.getLangLabel('STAGE')+' '+(currentStageIndex+1),text:stageTitle,offset:offset});
	}

	function goToOutdoorStage(){
		var stage = stages[currentStageIndex];
		var originStage = stage.stage_start_point;
		var destStage = stage.stage_end_point;
		setGoogleViewport(originStage,destStage);
	}


	function setGoogleViewport(pOrigin,pDestination){
		var origin = pOrigin;
		var destination = pDestination;
		var originPoint = {lat:((typeof origin.lat === 'function')?origin.lat():origin.lat),lng:((typeof origin.lng === 'function')?origin.lng():origin.lng)};
		var destPoint = {lat:((typeof destination.lat === 'function')?destination.lat():destination.lat),lng:((typeof destination.lng === 'function')?destination.lng():destination.lng)};
		if($('body').hasClass('vg-map')){
			$('body').addClass('gg-map').removeClass('vg-map');
		}
		gController.setViewPort({origin:originPoint,destination:destPoint});
	}

	this.removeInstructionInfo = function(){
		for (var i in instructionInfoList)
		{
			instructionInfoList[i].vg.hide()
			instructionInfoList[i].vg.remove();
			$(instructionInfoList[i].selector).remove();
		}
		// Since it is a remove, clear the arrays to avoid calling remove twice.
		instructionInfoList = [];
	}

	this.removeStageInfo =function(){
		for (var i in stagesInfoList)
		{
			stagesInfoList[i].vg.hide()
			stagesInfoList[i].vg.remove();
			$(stagesInfoList[i].selector).remove();
		}

		// Since it is a remove, clear the arrays to avoid calling remove twice.
		stagesInfoList = [];
	}

	function angleFromCoordinate(lat1, long1, lat2,long2) {
		var lat1 = toRadians(lat1);
		var lat2 = toRadians(lat2);
		var long1 = toRadians(long1);
		var long2 = toRadians(long2);
	    var dLon = (long2 - long1);
	    var y = Math.sin(dLon) * Math.cos(lat2);
	    var x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1)* Math.cos(lat2) * Math.cos(dLon);

	    var brng = Math.atan2(y, x);
	    brng = toDegrees(brng);
	    brng = (brng + 360) % 360;
	    brng = 360 - brng;

	    return brng;
	}

	function toDegrees (angle) {
	  return angle * (180 / Math.PI);
	}

	function toRadians(degrees) {
	  return degrees * Math.PI / 180;
	};

	function displayInstructionSegment(instruction, color)
	{
		if (typeof(instruction.positions) !== 'undefined')
		{
			var overlayPoints = [];
			for(var j = 0, jl = instruction.positions.length ; j < jl; j++)
			{
				var point = instruction.positions[j];
				// transfor to new coordinates
				point = mapviewer.convertLatLonToPoint(point);
				point.z = 2.5;
				overlayPoints.push(point);
			}
			var path_options = {
				floor: instruction.dataset,
				//url: trackImage, // only available on vg.mapviewer.kiosk.Mapviewer
				//speed: lSpeed, // only available on vg.mapviewer.kiosk.Mapviewer
				repeat: -1, // only available on vg.mapviewer.kiosk.Mapviewer
				thickness: 2.0,
				opacity: 0.5,
				color: color, // change the color of the line
				points: overlayPoints,
				overlay: true,
				// only available on vg.mapviewer.kiosk.Mapviewer, this makes it looks
				// better for sharp turns. Negative values will try to adapt the number of
				// segments to the length of the route, such that the absolute value
				// indicates the number of segments per "??unit??"
				segments: 1000
			};
			//
			var instructionPath = mapviewer.addRoutingPath(path_options);
			if (instructionPath)
			{
				instructionOverlays.push(instructionPath);
			}
		}
	}

	/**
	* @private
	* updates navigation div's with a given navigation instruction
	* @param {number} instruction index
	* @since 1.7.10 handle .duration and .durationString, does not update currentInstructionIndex
	*/
	function displayInstruction(index)
	{
		var instruction = instructions[index];
			/* It relies at least on the following images
			transit_instruction_end.png
			transit_instruction_down.png
			transit_instruction_up.png
			transit_instruction_start.png
			transit_instruction_straight.png
			transit_instruction_turn_gentle_left.png
			transit_instruction_turn_gentle_right.png
			transit_instruction_turn_left.png
			transit_instruction_turn_right.png
			transit_instruction_turn_sharp_left.png
			transit_instruction_turn_sharp_right.png
			transit_instruction_uturn_left.png
			transit_instruction_uturn_right.png
			transit_instruction_intermediate_destination.png
			*/
		if (instruction !== undefined)
		{


			jQuery('#instructions_detail').html(instruction['detail']);
			jQuery('#instructions_brief').html(instruction['brief']+': ');
			jQuery('#instructions_count').html((index+1)+'/'+numberOfInstructions);
			// since 1.7.10, if the instructions comes from javascript engine,
			// instruction.duration contains the duration in seconds, and durationString contains
			// for example 'in few minutes'
			// If the data comes from routing server, .duration will be the duration string.
			var durationString = (typeof(instruction['durationString']) !== 'undefined') ? instruction['durationString'] : instruction['duration'];
			jQuery('#instructions_time').html(durationString);
			jQuery('#instructions_icon').attr("src",imagePath + '/' + instruction['icon']);
		}


		// Configure how the line looks
		_this.removeInstructionOverlays();
		_this.removeStageOverlays();
		if (showCurrentInstructionSegment && typeof(instruction.positions) !== 'undefined')
		{
			displayInstructionSegment(instruction,0x00ff0000);

		}
		if(index === numberOfInstructions-1){
			mapviewer.trigger('MyNavigation.lastInstruction');
		}

	}

	function displayInstructionControls(index){
		var instruction = instructions[index];
		if (instruction !== undefined)
		{
			jQuery('#instructions_prev_button').removeClass('disabled');
			jQuery( '#instructions_prev_button' ).prop( "disabled", false );
			jQuery('#instructions_next_button').removeClass('disabled');
			jQuery( '#instructions_next_button' ).prop( "disabled", false );

			if(index === numberOfInstructions-1){
				jQuery('#instructions_next_button').addClass('disabled');
				jQuery( '#instructions_next_button' ).prop( "disabled", true );
				mapviewer.trigger('MyNavigation.lastInstruction');
			}
			if(index==0){
				jQuery('#instructions_prev_button').addClass('disabled');
				jQuery( '#instructions_prev_button' ).prop( "disabled", true );
			}
		}else{
			jQuery('#instructions_prev_button').addClass('disabled');
			jQuery( '#instructions_prev_button' ).prop( "disabled", true );
			jQuery('#instructions_next_button').addClass('disabled');
			jQuery( '#instructions_next_button' ).prop( "disabled", true );
		}
	}

	function displayStage(index){
		var stage = stages[index];
		// Configure how the line looks
		_this.removeInstructionOverlays();
		_this.removeStageOverlays();
		//If it's the last stage this event makes possible to render the YHA Lib
		if(index === stages.length-1){
			mapviewer.trigger('MyNavigation.lastStage');
		}
	}

	function displayStageControls(index){
		var stage = stages[index];
		if(stage!== undefined){
			jQuery('#stage_prev_button').removeClass('disabled');
			jQuery( '#stage_prev_button' ).prop( "disabled", false );
			jQuery('#stage_next_button').removeClass('disabled');
			jQuery( '#stage_next_button' ).prop( "disabled", false );

			if(index === totalStages-1){
				jQuery('#stage_next_button').addClass('disabled');
				jQuery( '#stage_next_button' ).prop( "disabled", true );
			}
			if(index==0){
				jQuery('#stage_prev_button').addClass('disabled');
				jQuery( '#stage_prev_button' ).prop( "disabled", true );
			}
		}else{
			jQuery('#stage_prev_button').addClass('disabled');
			jQuery( '#stage_prev_button' ).prop( "disabled", true );
			jQuery('#stage_next_button').addClass('disabled');
			jQuery( '#stage_next_button' ).prop( "disabled", true );
		}
	}

	function updateInstructionsPanel(){
		var $parentScroll = $('#map-instructions').parent();
		var panelOffset = $('#map-instructions').offset();
		$('.map-instructions .level-head').removeClass('active open');
		$('.map-instructions .list-item').removeClass('active');
		if(stepBystepMode){
			//var stageIndex = instructions[currentInstructionIndex]['stageIndex'];
			var stageIndex = instructions[currentInstructionIndex]['stage_index'];
			$('.map-instructions .level-head[data-index="'+stageIndex+'"]').addClass('active open');
			var itemOffset = $('.map-instructions .list-item[data-index="'+currentInstructionIndex+'"]').offset();
			if(currentInstructionIndex === 0){
				$parentScroll.scrollTop(0);
			}else{
				$parentScroll.scrollTop(itemOffset.top - panelOffset.top);
			}
			$('.map-instructions .list-item[data-index="'+currentInstructionIndex+'"]').addClass('active');
		}else{
			$('.map-instructions .level-head[data-index="'+currentStageIndex+'"]').addClass('active');
		}
	}


	function setStepByStepInstructions(){
		instructions =[];
		jQuery.each(stages,function(i,stage){
			jQuery.merge(instructions,stage.instructions);
		});
	}



	//var navigation = pNavigationData['navigation'];
	var vgIds = vg_ids;
	stepBystepMode = false;
	totalStages =complexRoute.getTotalStages();
	numberOfInstructions = complexRoute.getTotalInstructions();
	stages = complexRoute.getStages();

	if (stages.length > 0){
		//console.log(stages);
		//console.log('totalStages:'+totalStages);
		//console.log('numberOfInstructions:'+numberOfInstructions);
		setStepByStepInstructions();
		this.loadPreviewInstructions();
	}else{
		//TODO:Check Remove
		this.remove();
	}
};





// This code comes from VisioMove SDK: VgMyNavigationHelper.cpp
/**
 * @public
 * @name MyNavigationTranslator
 * @class Navigation Translator class used to translate navigation instructions coming from
 * the off-line routing engine (needs version 1.7.10 or greater)
 * Takes an instruction array and augments it with plain language descriptions.
 *
 * @see vg.mapviewer.kiosk.Mapviewer#computeRoute
 * @see vg.mapviewer.web.Mapviewer#computeRoute
 * @see MyNavigationTranslator#translateInstructions
 *
 * @example
var translator = new MyNavigationTranslator();
var languageString = "en"; // "fr"
translator.translateInstructions(routeResultData.navigation.instructions, languageString);
*
* @since 1.7.10
*/
var MyNavigationTranslator = function(pNavigation, vg_ids,pFloors,pBuildings, pDirectionsUseDistance,pEnableLandmarks) {
	this.stairsUp= 'transit_instruction_stairs_up.png';
	this.stairsDown = 'transit_instruction_stairs_down.png';
	this.escalatorUp= 'transit_instruction_escalator_up.png';
	this.escalatorDown = 'transit_instruction_escalator_down.png';
	this.changeBuildings = 'transit_instruction_layer_change.png';
	this.directionsUseDistance = pDirectionsUseDistance;
	this.enableLandmarks = pEnableLandmarks;
	this.metersToFeet = 3.2808;
	this.navigation = pNavigation;
	this.destinations = false;
	this.vg_ids = vg_ids;
	this.floors = pFloors;
	this.buildings = pBuildings;
	// available in 1.7.18
	if (pNavigation && pNavigation.route && pNavigation.route.request && pNavigation.route.request.dst)
	{
		this.destinations = pNavigation.route.request.dst;
	}
};

MyNavigationTranslator.prototype.getFloorByVgId=function(vgId){
	if(vgId === 'outside'){
		return {name:'Outside'};
	}else{
		var arr = jQuery.grep(this.floors, function( f ) {
		  return f.vg_floor_id === vgId;
		});
		return arr[0];
	}
}

/**
* @name getIDFromDestinationIndex
* @memberOf MyNavigationTranslator#
* @function
* @description
* returns the  poiID of the destinationIndex of the route.
* This is important for routes and navigations that do not traverse the given destinations in order, and to get the name of the final destination.
*
* @param {number} destination index
* @return {false or string} false if it cannot be found (for example, destination is a position), or if found, the poiID at a given destination index.
*
*/
MyNavigationTranslator.prototype.getIDFromDestinationIndex = function(pDestinationIndex)
{
	if (this.destinations === false || pDestinationIndex < 0 || pDestinationIndex >= this.destinations.length)
	{
		return false;
	}
	var destinationPoiID = this.destinations[pDestinationIndex];
	if (typeof(destinationPoiID) === 'string')
	{
		return destinationPoiID;
	}
	else if (typeof(destinationPoiID.id) === 'string')
	{
		// this is the result of mapviewer.getRoutingNode(string);
		return destinationPoiID.id;
	}
	return false;
}
/*
* @name getPlacenameFromDestinationIndex
* @memberOf MyNavigationTranslator#
* @function
* @description
* returns the  name of the destinationIndex pDestinationIndex of the route.
* This is important for routes and navigations that do not traverse the given destinations in order, and to get the name of the final destination.
*
* @param {number} destination index
* @return {false or string} false if it cannot be found, or if the the name of a destination at a given destination index.
*
* @example
var waypointName = this.getPlacenameFromDestinationIndex(lInstruction.destinationIndex);
if (waypointName)
{
	lStringNextAction += ' at '+waypointName;
}
*
*/
MyNavigationTranslator.prototype.getPlacenameFromDestinationIndex = function(pDestinationIndex)
{
	var poiID = this.getIDFromDestinationIndex(pDestinationIndex);
	if (poiID !== false)
	{
		return this.getPlacenameFromID(poiID);
	}
	return false;
}
/**
* @name getPlacenameFromID
* @memberOf MyNavigationTranslator#
* @function
* @description
* returns the  name of a placeID if known.
*
* @param {string} poiID
* @return {false or string} false if it cannot be found, or if the the name of a destination of a given poiID.
*
* @example
var nearPlaceName = navigationTranslator.getPlacenameFromID(lNearPlaceID);
if (nearPlaceName !== false)
{
...
}
*
*/
MyNavigationTranslator.prototype.getPlacenameFromID = function(pID)
{
	var vg_ids = this.vg_ids;
	var result = false;
	if ((typeof(vg_ids) !== 'undefined') && vg_ids.labels && vg_ids.labels[pID] && vg_ids.labels[pID].length > 1 && vg_ids.labels[pID][1])
	{
		result = vg_ids.labels[pID][1];
	}
	return result;
}


/**
* @name getBuildingByVgFloorId
* @memberOf MyNavigationTranslator#
* @function
* @description
* returns a building
*
* @param {string} vg_floor_id
* @return {object}
*
*/
MyNavigationTranslator.prototype.getBuildingByVgFloorId = function(vg_floor_id)
{
	var buildings = this.buildings;
	if(vg_floor_id === 'outside'){
		return {name:'outside'};
	}else{
		var building_id = vg_floor_id.split('-');
		var arr = jQuery.grep(buildings, function( f ) {
		  return f.vg_building_id === building_id[0];
		});
		return arr[0];
	}
}

MyNavigationTranslator.prototype.containBuildingString = function(string){
	string = string.toLowerCase();
	if(string.indexOf('building') !== -1){
		return true;
	}
	return false;
}



/**
* @public
* @name translateInstructions
* @function
* @memberOf MyNavigationTranslator#
*
* @param {Array} pInstructions array of instructions
* @param {String} [pLanguageString="en"] language string like "en" or "fr", must be in cLanguageMap, defaults to "en" if not found.
* @param {boolean} [mergeFloorChangeInstructions=false]
* @description
* translates all the instructions in pInstructions, augmenting each instruction with .brief, .detailed, .duration, .durationInSeconds
* @since 1.7.17 updated signature with mergeFloorChangeInstructions
*/
MyNavigationTranslator.prototype.translateInstructions = function(pInstructions, pTranslator,pLegMetadata, pMergeFloorChangeInstructions)
{
	// setup language
    //var languageID = (pLanguageString && this.cLanguageMap[pLanguageString]) || this.cLanguageMap["en"];

    var _this = this;

    pMergeFloorChangeInstructions = pMergeFloorChangeInstructions || false;

    for (var index = 0,l = pInstructions.length; index < l; index++)
    {
        _this._translateInstruction(pInstructions,index, pTranslator, pMergeFloorChangeInstructions,pLegMetadata);
    }
}


/*
* Input:
maneuverType
dataset
modality
height
duration: in seconds
*/

/**
* @private
* @name _translateInstructions
* @function
* @memberOf MyNavigationTranslator#
*
* @param {Array} pInstructions array of instructions
* @param {number} pIndex index of instruction to translate
* @param {number} [pLanguageIndex=0] language index in cLanguageMap.
* @param {boolean} [pMergeFloorInstructions=false] if mergeFloorInstructions flag was used when computing the instructions, by default false
* @description
* translates all the instructions in pInstructions, augmenting each instruction with .brief, .detailed, .duration, .durationInSeconds
*/
MyNavigationTranslator.prototype._translateInstruction = function(pInstructions, pIndex, pTranslator, pMergeFloorInstructions,pLegMetadata)
{
	var routeDirection = pLegMetadata.direction;
	var modeTransportation = pLegMetadata.modeTransportation;
	var destination = pLegMetadata.legDestination;
	var origin = pLegMetadata.legOrigin;
	var hasShuttle = pLegMetadata.hasShuttle;
	// default to 0
	//pLang = pTranslator.getLanguageIndex() || 0;
	// default pMergeFloorInstructions to false
	pMergeFloorInstructions = pMergeFloorInstructions || false;

	var lSkipNearPlaces = false;

	var lNumInstructions = pInstructions.length;

    var lInstruction = pInstructions[pIndex];
    var lNextInstruction = pInstructions[pIndex+1];

    var lInstructionManeuverIndex = pTranslator.cManeuverType2Index[lInstruction.maneuverType];

    var lStringAction = '';
    var lStringDuration = '';
    var lStringNextAction = '';
	var lStringVincinity = '';
	var lStringPreAction = '';
	var lStringFloorChangeMethod = '';
	var lStringIcon = lInstruction.icon;
	var lStringDistance = '';

    //enum StringType
    /** Duration link word ("for" in English, "pendant" in French) */
    var eStringFor = 0;
    /** Duration link word ("then" in English, "puis" in French) */
    var eStringThen = 1;
    /** Duration link word ("and" in English, "et" in French) */
    var eStringAnd = 2;
    /** Duration link word ("near" in English, "‡ proximitÈ de" in French) */
    var eStringNear = 3;
    /** Duration link word ("using" in English, "en empruntant" in French) */
    var eStringUsing = 4;

	var eStringStairway = 5;
	var eStringEscalator = 6;
	var eStringLift = 7;

    /** Last entry does not identify a string it is the number of strings */
    var eStringCount = 8;

    var eVgManeuverTypeUnknown = 0;
    var eVgManeuverTypeGoStraight = 1;
    var eVgManeuverTypeTurnGentleRight = 2;
    var eVgManeuverTypeTurnGentleLeft = 3;
    var eVgManeuverTypeTurnRight = 4;
    var eVgManeuverTypeTurnLeft = 5;
    var eVgManeuverTypeTurnSharpRight = 6;
    var eVgManeuverTypeTurnSharpLeft = 7;
    var eVgManeuverTypeUTurnRight = 8;
    var eVgManeuverTypeUTurnLeft = 9;
    var eVgManeuverTypeStart = 10;
    var eVgManeuverTypeEnd = 11;
    var eVgManeuverTypeGoUp = 12;
    var eVgManeuverTypeGoDown = 13;
    var eVgManeuverTypeChangeModality = 14;
    var eVgManeuverTypeChangeLayer = 15;
    var eVgManeuverTypeWaypoint = 16;
    var eVgManeuverTypeMax = 17;


    switch(lInstruction.maneuverType)
    {
	case 'eVgManeuverTypeEnd':
		//lStringAction = this.cActionStringTable[pLang][lInstructionManeuverIndex];
		lStringAction =  this._getLastInstructionString(destination,routeDirection,modeTransportation,pTranslator,hasShuttle);
		var lDestinationName = this.getPlacenameFromDestinationIndex(lInstruction.destinationIndex);
		if (lDestinationName)
		{
			lStringAction += ': ' + lDestinationName;
			lSkipNearPlaces = true;
		}
    	break;
    case 'eVgManeuverTypeChangeModality':
    case 'eVgManeuverTypeChangeLayer':
    	lStringAction = this._getChangeBuildingsInstructionString(lInstruction,lNextInstruction,pTranslator);
    	break;
    case 'eVgManeuverTypeGoDown':
    case 'eVgManeuverTypeGoUp':
    case 'eVgManeuverTypeStart':
        lStringAction = pTranslator.getcActionStringTable(lInstructionManeuverIndex);
    break;
	case 'eVgManeuverTypeWaypoint':
		if (typeof(lInstruction.destinationIndex) !== 'undefined')
		{
		    // This is a waypoint instructions
		    lStringAction = pTranslator.getcActionStringTable(eVgManeuverTypeWaypoint);
			var lWaypointName = this.getPlacenameFromDestinationIndex(lInstruction.destinationIndex);
			if (lWaypointName)
			{
				lStringAction += ': ' + lWaypointName;
				lSkipNearPlaces = true;
			}
		}
		break;
    case 'eVgManeuverTypeGoStraight':
        lStringAction = pTranslator.getcActionStringTable(eVgManeuverTypeGoStraight);
        if(!this.directionsUseDistance){
        	lStringDuration = pTranslator.getcStringTable(eStringFor) + this._timeToText(lInstruction.duration/60.0, pTranslator);
        }else{
        	lStringDistance = pTranslator.getcStringTable(eStringFor) + this._distanceToText(lInstruction.length*this.metersToFeet, pTranslator);
        }
        if (pMergeFloorInstructions)
        {
            // When instruction merging is active, we have to test the next
            // instruction's layer/modality to know if we should instruct to change
            // level/transportation.
            if (!lNextInstruction)
            {
                // Last instruction means next action is "you have arrived"
                lStringNextAction = pTranslator.getcStringTable(eStringThen) + pTranslator.getcNextActionStringTable(eVgManeuverTypeEnd);

				var lDestinationName = this.getPlacenameFromDestinationIndex(lInstruction.destinationIndex);
				if (lDestinationName)
				{
					lStringNextAction += ': ' + lDestinationName;
					lSkipNearPlaces = true;
				}
                break;
            }
            var lInstructionLayer = lInstruction.dataset;
            var lNextInstructionLayer = lNextInstruction && lNextInstruction.dataset;
            if (lInstructionLayer != lNextInstructionLayer)
            {
                // Test whether we go up or down or is a change of layers (possibly building)

                if (lNextInstruction.height > lInstruction.height)
                {
                    lStringNextAction = pTranslator.getcStringTable(eStringThen) + pTranslator.getcNextActionStringTable(eVgManeuverTypeGoUp);
                }
                else if (lNextInstruction.height < lInstruction.height)
                {
                    lStringNextAction = pTranslator.getcStringTable(eStringThen) + pTranslator.getcNextActionStringTable(eVgManeuverTypeGoDown);
                }
                else
                {
                    // then is a change of layer (building)
                    lStringNextAction = pTranslator.getcStringTable(eStringThen) + pTranslator.getcNextActionStringTable(eVgManeuverTypeChangeLayer);
                }

                if (lNextInstruction.modality != lInstruction.modality)
                {
                    // This works here because with mMergeFloorChangeInstructions
                    // The next instruction will have the right modality
                    lStringNextAction += pTranslator.getcStringTable(eStringAnd) + pTranslator.getcNextActionStringTable(eVgManeuverTypeChangeModality);
                }
            }
            else if (lNextInstruction.modality != lInstruction.modality)
            {
                lStringNextAction += pTranslator.getcStringTable(eStringThen) + pTranslator.getcNextActionStringTable(eVgManeuverTypeChangeModality);
            }

			if (lNextInstruction && lNextInstruction.destinationIndex !== lInstruction.destinationIndex)
			{
			    // This instruction finishes at a waypoint
			    lStringNextAction += /*this.cStringTable[pLang][eStringAnd]*/ pTranslator.getcStringTable(eStringThen) + pTranslator.getcNextActionStringTable(eVgManeuverTypeWaypoint);

				var lWaypointName = this.getPlacenameFromDestinationIndex(lInstruction.destinationIndex);
				if (lWaypointName)
				{
					lStringNextAction += ': ' + lWaypointName;
					lSkipNearPlaces = true;
				}
			}
        }
        else
        {
            // When instruction merging is inactive, we have to test the next
            // instruction's type to know if we should instruct to change level
            // or transportation mode.
			// no merge instructions.
            if (pIndex < (lNumInstructions - 1))
            {
                {
                    switch (lNextInstruction.maneuverType)
                    {
                        case 'eVgManeuverTypeChangeLayer':
                        lStringNextAction = pTranslator.getcStringTable(eStringThen) + pTranslator.getcNextActionStringTable(eVgManeuverTypeChangeLayer);
                        break;
                        
                        // We skip the change modality, as the modality of the
                        // instruction of eVgManeuverTypeChangeModality
                        // is the same as the current modality, thus the text
                        // will be wrong (#6684)
                        //
                        //case VgNavigationModule::eVgManeuverTypeChangeModality:
                        case 'eVgManeuverTypeEnd':
							lStringNextAction = pTranslator.getcStringTable(eStringThen) + pTranslator.getcNextActionStringTable(pTranslator.cManeuverType2Index[lNextInstruction.maneuverType]);
							lStringNextAction += this._getNextActionToTypeEnd(destination,routeDirection,modeTransportation,pTranslator,hasShuttle);
							var lDestinationName = this.getPlacenameFromDestinationIndex(lNextInstruction.destinationIndex);
							if (lDestinationName)
							{
								lStringNextAction += ': ' + lDestinationName;
								lSkipNearPlaces = true;
							}
							break;
                        case 'eVgManeuverTypeGoDown':
                        case 'eVgManeuverTypeGoUp':
                            lStringNextAction = pTranslator.getcStringTable(eStringThen) + pTranslator.getcNextActionStringTable(pTranslator.cManeuverType2Index[lNextInstruction.maneuverType]);
                            break;
                        case 'eVgManeuverTypeWaypoint':
                            // This is the previous instruction before the actual stop
                            lStringNextAction = pTranslator.getcStringTable(eStringThen) + pTranslator.getcNextActionStringTable(pTranslator.cManeuverType2Index[lNextInstruction.maneuverType]);

							var lWaypointName = this.getPlacenameFromDestinationIndex(lNextInstruction.destinationIndex);
							if (lWaypointName)
							{
								lStringNextAction += ': ' + lWaypointName;
								lSkipNearPlaces = true;
							}

                            break;
                        default:
                            break;
                    }
                }
            }
            else
            {
                // We are on last instruction, so no next action.
            }
        }
        break;

    default:
        // These are turn left/right instructions
        lStringAction = pTranslator.getcActionStringTable(eVgManeuverTypeGoStraight);
        if(!this.directionsUseDistance){
        	lStringDuration = pTranslator.getcStringTable(eStringFor) + this._timeToText(lInstruction.duration/60.0, pTranslator);
        }else{
        	lStringDistance = pTranslator.getcStringTable(eStringFor) + this._distanceToText(lInstruction.length*this.metersToFeet, pTranslator);
        }
        lStringNextAction = pTranslator.getcStringTable(eStringThen) + pTranslator.getcNextActionStringTable(pTranslator.cManeuverType2Index[lInstruction.maneuverType]);
        break;
    } // switch statement


    var lPlaces = lInstruction.nearPlaces;
    if ((pMergeFloorInstructions && pIndex >= (lNumInstructions - 1)) ||
        (!pMergeFloorInstructions && pIndex >= (lNumInstructions - 2)))
    {
        lSkipNearPlaces = true;
    }


	var lThereIsFloorChange = false;
	var lThereIsFloorChangeAttribute = false;

    // If current instruction if a change floor kind OR
    // without mergefloor the next instruction is the change floor kind OR
    // with mergefloor, the height of the current and next instruction are different
    if ((lInstruction.maneuverType == 'eVgManeuverTypeGoUp'
         || lInstruction.maneuverType == 'eVgManeuverTypeGoDown')
        ||
    	(!pMergeFloorInstructions
        && pIndex < (lNumInstructions - 2)
        && (pInstructions[pIndex + 1].maneuverType == 'eVgManeuverTypeGoUp'
        	|| pInstructions[pIndex + 1].maneuverType == 'eVgManeuverTypeGoDown'))
        ||
        (pMergeFloorInstructions
        && pIndex < (lNumInstructions - 1)
        && lInstruction.height != pInstructions[pIndex + 1].height)
		)
    {
        lThereIsFloorChange = true;
        lSkipNearPlaces = false; //JEWISH-121 - We added this line to avoid skipping the near places when there is a floor change. This is part of the issue NIH-1139
		var lFound = false;
		for (var ia = 0, la = lInstruction.attributes.length; ia < la ; ia++)
		{
			var attribute = lInstruction.attributes[ia];
			switch(attribute)
			{
				case 'stairway':
					//lStringFloorChangeMethod = pTranslator.getcStringTable(eStringUsing) + pTranslator.getcStringTable(eStringStairway);
					if (lPlaces && lPlaces.length > 0){
						lStringFloorChangeMethod = pTranslator.getcStringTable(eStringUsing);
					}else{
						lStringFloorChangeMethod = pTranslator.getcStringTable(eStringUsing) + pTranslator.getcStringTable(eStringStairway) + ' ';
					}
					//Add the icon for stairs. Defaul icon Elevators
					lStringIcon = (lInstruction.maneuverType == 'eVgManeuverTypeGoUp')?this.stairsUp:(lInstruction.maneuverType == 'eVgManeuverTypeGoDown')?this.stairsDown:lInstruction.icon;
					lFound = true;
					break;
				case 'escalator':  // When the change of floor is using escalator, we don't add the word "escalator" before the name of the escalator
					//lStringFloorChangeMethod = pTranslator.getcStringTable(eStringUsing) + pTranslator.getcStringTable(eStringEscalator);
					if (lPlaces && lPlaces.length > 0){
						lStringFloorChangeMethod = pTranslator.getcStringTable(eStringUsing);
					}else{
						lStringFloorChangeMethod = pTranslator.getcStringTable(eStringUsing) + pTranslator.getcStringTable(eStringEscalator) + ' ';
					}
					
					//Add the icon for stairs. Defaul icon Elevators
					lStringIcon = (lInstruction.maneuverType == 'eVgManeuverTypeGoUp')?this.escalatorUp:(lInstruction.maneuverType == 'eVgManeuverTypeGoDown')?this.escalatorDown:lInstruction.icon;
					lFound = true;
					break;
				case 'lift':
					lStringFloorChangeMethod = pTranslator.getcStringTable(eStringUsing) + pTranslator.getcStringTable(eStringLift) + ' ';
					lFound = true;
					break;
			}
			if (lFound)
			{
				break;
			}
		}
    }

    //JEWISH-121
    // If current instruction it's inside but the next is outside but has a different layer (height) maneautype should be changelayer
    // If current instruction it's outside but the next is inside but has a different layer (height) maneautype should be changelayer
    if ((lInstruction.maneuverType == 'eVgManeuverTypeGoUp'
         || lInstruction.maneuverType == 'eVgManeuverTypeGoDown') 
    	 && pIndex < (lNumInstructions - 2)
    	 && lInstruction.dataset !== lNextInstruction.dataset
    	 && ((lInstruction.dataset === 'outside' && lNextInstruction.dataset!=='outside')
    	 	|| (lInstruction.dataset !== 'outside' && lNextInstruction.dataset==='outside'))
    	 ){
		lThereIsFloorChange = false;
		lSkipNearPlaces = true;
		lStringFloorChangeMethod = '';
    	lStringAction = this._getChangeBuildingsInstructionString(lInstruction,lNextInstruction,pTranslator);
    	lStringIcon = this.changeBuildings;
    }

	if (!lSkipNearPlaces)
    {
        if (lPlaces && lPlaces.length > 0)
        {
			// places are sorted by internal angle, if you wanted them sorted by distance.
			//lPlaces.sort(function(a,b){return a.distance - b.distance;});
            // TODO get place name
            var lID = lPlaces[0].id;
            var lPlaceName = this.getPlacenameFromID(lID);

            // For debugging, put ID if placename is not found.
            //lPlaceName = lPlaceName || lID;
			if (lThereIsFloorChange)
			{
				// If there is a floor change we try to find the name of the escalator
				// or lift
				// In order for this to work the stair or elevator must have a routing ID
				if (lID.match(/escalator/i)
					|| lID.match(/elevator/i)
					|| lID.match(/lift/i)
					|| lID.match(/stair/i)
					|| lID.match(/EL-/i)
					|| lID.match(/ST-/i)
					|| lID.match(/ES-/i))
				{
					// we say using, if the near POI is a [E]scalator or [E]levator.
					// else we say nothing.
					//lStringVincinity = pTranslator.getcStringTable(eStringUsing) + lID;
					//lStringVincinity = pTranslator.getcStringTable(eStringUsing);
					lStringVincinity = (lPlaceName !== false)?lPlaceName:lID;
				}
			}
			else if (lPlaceName !== false
            	&& lInstruction.maneuverType != 'eVgManeuverTypeChangeModality'
            	&& lInstruction.maneuverType != 'eVgManeuverTypeChangeLayer'
            	&& lInstruction.maneuverType != 'eVgManeuverTypeEnd'
            	&& this.enableLandmarks)
            {
                lStringVincinity = pTranslator.getcStringTable(eStringNear) + lPlaceName;
            }
        }
    }

    // Uncomment this line if you don't want landmark navigation.
    // lStringVincinity = ''; 
    if(pIndex === 0){
    	lStringPreAction = this._getFirstInstructionPreAction(origin,routeDirection,modeTransportation,pTranslator);
    }
    if(!this.directionsUseDistance){
    	lInstruction.detail = lStringPreAction + lStringAction + lStringDuration + lStringNextAction;
    }else{
    	lInstruction.detail = lStringPreAction + lStringAction + lStringDistance + lStringNextAction;
    }
    if ((lStringNextAction && lStringNextAction != '') ||
    	lInstruction.maneuverType == 'eVgManeuverTypeGoUp' ||
    	lInstruction.maneuverType == 'eVgManeuverTypeGoDown')
    {
    	lInstruction.detail += lStringFloorChangeMethod + lStringVincinity;
    }
    lInstruction.brief = lStringAction;
    lInstruction.durationString = lStringDuration;
    lInstruction.distanceString = lStringDistance;
    lInstruction.detail = this._replaceTokens(lInstruction.detail, lInstruction, lNextInstruction);
    lInstruction.brief = this._replaceTokens(lInstruction.brief, lInstruction, lNextInstruction);
    lInstruction.durationString = this._replaceTokens(lInstruction.durationString, lInstruction, lNextInstruction);
    lInstruction.icon = lStringIcon;

} // end NavigationSolver.prototype.translateInstruction

/**
* @private
* @name _replaceTokens
* @memberOf MyNavigationTranslator#
* @function
* @description
* replaces tokens on a string. %d: duration in minutes, %m: current modality, %l: current dataset
* %M modality of next instruction, %L: next dataset name.
* @param {String} string with tokens
* @param {InstructionObject} pInstructionCurrent
* @param {InstructionObject} pInstructionNext
* @return string with tokens replaced
*/
MyNavigationTranslator.prototype._replaceTokens = function(pStringWithTokens,pInstructionCurrent,pInstructionNext)
{
	var floor = null;
    // Replaces occurrances of a token with contextual data
    if(typeof(pInstructionCurrent) !== 'undefined')
    {
        var lDurationInMinutes = Math.floor(pInstructionCurrent.duration/60.0);
        var lDistanceInFeet = Math.floor(pInstructionCurrent.length*this.metersToFeet);
        floor = this.getFloorByVgId(pInstructionCurrent.dataset);
        
        if(!this.directionsUseDistance){
        	pStringWithTokens = pStringWithTokens.replace('%d', lDurationInMinutes);
        }else{
        	pStringWithTokens = pStringWithTokens.replace('%f', lDistanceInFeet);
        }
        pStringWithTokens = pStringWithTokens.replace('%m', pInstructionCurrent.modality);
        //pStringWithTokens = pStringWithTokens.replace('%l', pInstructionCurrent.dataset);
        pStringWithTokens = pStringWithTokens.replace('%l', floor.name);
    }

    if (typeof(pInstructionNext) !== 'undefined')
    {
    	floor = this.getFloorByVgId(pInstructionNext.dataset);
        pStringWithTokens = pStringWithTokens.replace('%M', pInstructionNext.modality);
        //pStringWithTokens = pStringWithTokens.replace('%L', pInstructionNext.dataset);
        pStringWithTokens = pStringWithTokens.replace('%L', floor.name);
    }
    return pStringWithTokens;
}

/**
* @private
* @name _timeToText
* @function
* @memberOf MyNavigationTranslator#
* @description
* converts time in minutes to a string in a language
* @param {number} pTimeInMinutes
* @param {number} pLang
* @return string describing duration
*/
MyNavigationTranslator.prototype._timeToText = function(pTimeInMinutes, pTranslator)
{
    if (pTimeInMinutes < 1.0)
    {
        return pTranslator.getcTimeStringTable(0);
    }
    else if (pTimeInMinutes < 2.0)
    {
        return pTranslator.getcTimeStringTable(1);
    }
    else
    {
        return pTranslator.getcTimeStringTable(2);
    }
}

/**
* @private
* @name _distanceToText
* @function
* @memberOf MyNavigationTranslator#
* @description
* converts length in feet to a string in a language
* @param {number} pLengthInFeets
* @param {number} pLang
* @return string describing feet
*/
MyNavigationTranslator.prototype._distanceToText = function(pLengthInFeets, pTranslator)
{
    if (pLengthInFeets >= 1.0 && pLengthInFeets < 2.0)
    {
        return pTranslator.getcDistanceStringTable(0);
    }
    else
    {
        return pTranslator.getcDistanceStringTable(1);
    }
}

MyNavigationTranslator.prototype._getLastInstructionString = function(pDestination,pRouteDirection,pModeTrans,pTranslator,pHasShuttle){
	if(pRouteDirection === 'indoor-indoor' || pRouteDirection === 'outdoor-indoor'){
		return pTranslator.getLangLabel('NAV_YOU_HAVE_ARRIVED')+pTranslator.getLangLabel('AT') + pDestination.name;
	}else{
		if(pModeTrans==='driving'){
			return pTranslator.getLangLabel('NAV_YOU_HAVE_ARRIVED')+pTranslator.getLangLabel('AT')+ pDestination.name;
		}else if(pModeTrans==='transit' && pHasShuttle){
			return pTranslator.getLangLabel('TAKE_SHUTTLE');
		}else{
			return pTranslator.getLangLabel('NAV_YOU_HAVE_ARRIVED')+pTranslator.getLangLabel('AT')+ pDestination.name;
		}
	}
}

MyNavigationTranslator.prototype._getNextActionToTypeEnd = function(pDestination,pRouteDirection,pModeTrans,pTranslator,pHasShuttle){
	if(pRouteDirection === 'indoor-indoor'){
		return pTranslator.getLangLabel('AT') + pDestination.name;
	}else{
		if(pModeTrans==='driving'){
			return pTranslator.getLangLabel('AT') + pDestination.name;
		}else if(pModeTrans==='transit' && pHasShuttle && pRouteDirection === 'indoor-outdoor'){
			return pTranslator.getLangLabel('AT')+pTranslator.getLangLabel('SHUTTLE_STOP');
		}else{
			return pTranslator.getLangLabel('AT') + pDestination.name;
		}
	}
}

MyNavigationTranslator.prototype._getFirstInstructionPreAction = function(pOrigin,pRouteDirection,pModeTrans,pTranslator){
	if(pRouteDirection === 'indoor-indoor' || pRouteDirection === 'indoor-outdoor'){
		return '';
	}else{
		return pTranslator.getLangLabel('NAV_ENTER_BUILDING')+'. ';
	}
}

MyNavigationTranslator.prototype._getChangeBuildingsInstructionString = function(lInstruction,lNextInstruction,pTranslator){
	var lInstructionBuilding = this.getBuildingByVgFloorId(lInstruction.dataset);
	var lNextInstructionBuilding = this.getBuildingByVgFloorId(lNextInstruction.dataset);
	var lInstructionBuildingString = this.containBuildingString(lInstructionBuilding.name)?'':' '+pTranslator.getLangLabel('BUILDING');
	var lNextInstructionBuildingString = this.containBuildingString(lNextInstructionBuilding.name)?'':' '+pTranslator.getLangLabel('BUILDING');

	if(lInstructionBuilding.name === 'outside'){
		return lStringAction = pTranslator.getLangLabel('NAV_ENTER') + ' ' + lNextInstructionBuilding.name + lNextInstructionBuildingString;
	}else if (lNextInstructionBuilding.name === 'outside'){
		return lStringAction = pTranslator.getLangLabel('NAV_EXIT') + ' ' +lInstructionBuilding.name + lInstructionBuildingString + pTranslator.getLangLabel('AND') + ' ' + pTranslator.getLangLabel('NAV_GO_OUTSIDE');
	}else{
		return lStringAction = pTranslator.getLangLabel('NAV_EXIT') + ' '+ lInstructionBuilding.name + lInstructionBuildingString + pTranslator.getLangLabel('AND') + pTranslator.getLangLabel('NAV_ENTER') + ' ' + lNextInstructionBuilding.name + lNextInstructionBuildingString;
    }
}
